import styled from "styled-components";

const ResultContainer = styled.div`
  width: 50vw;
  display: flex;

  justify-content: center;
`;

export default { ResultContainer };
